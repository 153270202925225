<template>
  <div class="d-flex flex-column flex-root">

  <div class="d-flex flex-column flex-root">
			<!--begin::Login-->
			<div class="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white bgi-position-y-bottom bgi-position-x-right" id="kt_login" style="background-image: url(/media/bg/white-abstract.jpg)">
				<!--begin::Aside-->
				<div class="login-aside d-flex flex-column flex-row-auto bgi-no-repeat bgi-position-y-bottom  bgi-position-x-right" style="background-image: url(/media/bg/login.jpg);background-size: 100% auto;">
					<!--begin::Aside Top-->
					<div class="d-flex flex-column-auto flex-column pt-lg-30 pt-7">
						<!--begin::Aside header-->
						<a :href="'https://' + settings.project_url" v-if="settings.white_logo" target="_blank" class="text-center mb-lg-10 mb-4">
							<img :src="'data:image/png;base64,' + settings.white_logo.image_base_64" class="max-h-lg-60px max-h-40px" alt="" />
						</a>
						<a href="https://www.pixio.cz" v-else target="_blank" class="text-center mb-lg-10 mb-4">
							<img src="/media/logos/pixio-white.svg" class="max-h-lg-60px max-h-40px" alt="" />
						</a>
						<!--end::Aside header-->
						<!--begin::Aside title-->
						<h3 class="text-center font-size-h3 font-size-h3-lg d-none d-lg-block" style="color: #6e73ac;">{{ settings.project_name }}</h3>
						<!--end::Aside title-->
					</div>
					<!--end::Aside Top-->
					<!--begin::Aside Bottom-->
					<div class="aside-img d-flex flex-row-fluid"></div>
					<!--end::Aside Bottom-->
				</div>
				<!--begin::Aside-->
				<!--begin::Content-->
				<div class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
					<!--begin::Content body-->
					<div class="d-flex flex-column-fluid flex-center" >
            			<router-view></router-view>
					</div>
					<!--end::Content body-->
					<!--begin::Content footer-->
					<div class="d-flex justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0">
						<div class="text-dark-50 font-size-lg font-weight-bolder mr-10">
							<span class="mr-1">© {{new Date().getFullYear()}} {{ $t("main.company_name") }}</span>
						</div>
						<a href="https://www.pixio.cz/ochrana-osobnich-udaju" target="_blank" class="text-primary font-weight-bolder font-size-lg">{{ $t("title.gdpr") }}</a>
						<a href="https://www.pixio.cz/kontakt" target="_blank" class="text-primary ml-5 font-weight-bolder font-size-lg">{{ $t("title.support") }}</a>
					</div>
					<!--end::Content footer-->
				</div>
				<!--end::Content-->
			</div>
			<!--end::Login-->
		</div>

		

  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import { mapState } from "vuex";
import ApiService from "@/core/services/api.service";

export default {
  name: "auth",
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  },
   data() {
    return {
      settings: {
        white_logo: null,
		project_name: "PIXIO CRM",
		project_url: 'www.pixio.cz'
      },
    };
  },
  beforeMount() {
    this.getBasicSettings();
  },
  methods: {
	getBasicSettings() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet("/info")
        .then((response) => {
          this.settings = response.data;
        });
    }
  }
};
</script>
